/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DateTime } from 'luxon';
import { useGetRoutingRequest } from '../../hooks/useRequestManager';
import { useContext } from 'react';
import { AccessContext } from '../DecisionViewer';
import { Alert } from '@cimpress/react-components';
import { getLastRetryDate } from '../../helpers/errorStatus';
import { Problem } from '../../types';

const noOptionsWrapperStyles = css`
  overflow-y: scroll;

  > {
    h3 {
      margin: 2.24rem 0 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
    }

    p {
      margin: 1.6rem 0;
    }

    p:last-of-type {
      margin: 1.6rem 0 0;
    }

    p:first-of-type {
      margin: 0 0 1.6rem;
    }
  }
`;

export const NoOptionsNetworkError = ({
  routingProblem,
  routingRequestUrl,
}: {
  routingProblem: Problem | undefined;
  routingRequestUrl: string | undefined;
}) => {
  const { accessToken } = useContext(AccessContext);

  const { data: routingRequestData, isLoading: isLoadingRoutingRequest } = useGetRoutingRequest({
    accessToken,
    routingRequestUrl,
  });

  if (isLoadingRoutingRequest || !routingProblem) {
    return null;
  }

  const lastRetryDate = getLastRetryDate(routingRequestData?.routingRequest);

  const attemptedRetriesSentence = routingRequestData && routingRequestData.routingRequest && lastRetryDate && (
    <p data-testid="attempted-retries-sentence">
      The routing request was last tried on {DateTime.fromISO(lastRetryDate).toLocaleString(DateTime.DATE_HUGE)} at{' '}
      {DateTime.fromISO(lastRetryDate).toLocaleString(DateTime.TIME_SIMPLE)} and the request has been retried{' '}
      {routingRequestData.routingRequest.attemptsCount} times.
    </p>
  );

  return (
    <div css={noOptionsWrapperStyles} data-testid="no-options-network-error">
      <Alert
        title={routingProblem.title}
        message={<p style={{ wordBreak: 'break-word' }}>{routingProblem.detail}</p>}
        dismissible={false}
      />
      <h3>Network Error</h3>
      <p>
        We encountered a network error while attempting to retrieve any available Fulfillment Options for routing this
        item. This indicates that Routing or dependent services are experiencing technical difficulties.
      </p>

      {attemptedRetriesSentence}

      <p>
        Requests stuck in routing are retried multiple times but may not succeed without manual intervention. For
        questions or status updates, please reach out to MCP Platform Support for assistance in the{' '}
        <a href="https://cimpress.slack.com/archives/C7CNT9E2Z" target="_blank" rel="noreferrer">
          #mcp-platform-support
        </a>{' '}
        Slack channel.
      </p>
    </div>
  );
};
