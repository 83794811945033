/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Accordion from '@cimpress/react-components/lib/Accordion';
import { silver } from '@cimpress/react-components/lib/colors';
import { isEmpty } from 'lodash';
import Events from './Events';
import Details from './Details';
import ItemActions from './ItemActions';
import ItemHeader from './ItemHeader';
import {
  loadOrder,
  loadBuyerProductPricingForItem,
  loadBuyerShippingPricingForItem,
  loadFulfiller,
  loadFulfillerDetails,
  loadProductManufacturing,
  loadPrintJob,
} from '../../actions/orderv2actions';
import { printJobRel } from '../../constants/printJob';
import CancellationRequests from './CancellationRequests';
import ChangeRequests, { useChangeRequestsData } from './ChangeRequests';
import Shipments, { useShipmentData } from './Shipments';
import { useItemHistoryData } from './statusOverview/ItemHistory';
import StatusOverview from './statusOverview/StatusOverview';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

require('./ItemDetailsAccordion.css');

const tabs = {
  DETAILS: 'DETAILS',
  OVERVIEW: 'OVERVIEW',
  EVENTS: 'EVENTS',
  STATUS: 'STATUS',
  ERROR: 'ERROR',
  CHANGE_REQUESTS: 'CHANGE_REQUESTS',
  CANCELLATION_REQUESTS: 'CANCELLATION_REQUESTS',
  SHIPMENTS: 'SHIPMENTS',
};

const accordionBodyStyle = {
  background: 'white',
  border: `1px solid ${silver}`,
  borderTop: 'none',
  padding: '20px',
};

const Item = ({
  item,
  itemList,
  isOpen,
  scrollIntoView,
  onContainerClick,
  permissions,
  loadOrder,
  loadBuyerProductPricingForItem,
  loadBuyerShippingPricingForItem,
  loadFulfiller,
  loadFulfillerDetails,
  loadProductManufacturing,
  loadPrintJob,
  isLoadingFulfillers,
  intl,
}) => {
  const [tab, setTab] = useState(tabs.DETAILS);
  // map of eventId -> isExpanded. Used to control whether event is expanded or collapsed
  const [eventExpandedMap, setEventExpandedMap] = useState({});

  const changeRequestsData = useChangeRequestsData(item.itemId, item);
  const shipmentData = useShipmentData(item.shipments, itemList);
  const itemHistoryData = useItemHistoryData(item.itemId);

  const ref = useRef(null);

  useEffect(() => {
    const { itemId, _links } = item;

    if (_links) {
      if (_links.buyerProductPrice) {
        loadBuyerProductPricingForItem(_links.buyerProductPrice.href, itemId);
      }

      if (_links.buyerShippingPrice) {
        loadBuyerShippingPricingForItem(_links.buyerShippingPrice.href, itemId);
      }

      if (_links.productConfiguration) {
        loadProductManufacturing(itemId, _links.productConfiguration.href);
      }

      if (_links[printJobRel]) {
        loadPrintJob(itemId, _links[printJobRel].href);
      }
    }
  }, [
    item.itemId,
    loadBuyerProductPricingForItem,
    loadBuyerShippingPricingForItem,
    loadPrintJob,
    loadProductManufacturing,
  ]);

  // Only load individual fulfiller info if we're not actively loading the full list of
  // fulfillers.  We want want to avoid redundant fulfiller requests.
  useEffect(() => {
    if (!isLoadingFulfillers) {
      const { globalFulfillerId } = item;

      if (globalFulfillerId) {
        loadFulfiller(globalFulfillerId);
        loadFulfillerDetails(item._links?.fulfillmentConfiguration?.href);
      }
    }
  }, [isLoadingFulfillers, item, loadFulfiller, loadFulfillerDetails]);

  useEffect(() => {
    if (scrollIntoView && ref.current && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoView]);

  const onTabClicked = e => {
    const newTab = e.target.name;

    trackEvent(TrackingEventTitles.ITEM_TAB, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_TAB_LABEL]: newTab,
    });

    setTab(newTab);
  };

  const onHeaderClick = (event, isNowOpen) => onContainerClick(item.itemId, isNowOpen);

  const onMoreDetailsClick = e => {
    e.preventDefault();
    e.stopPropagation();

    onHeaderClick(e, true);
    setTab(tabs.OVERVIEW);
  };

  const toggleExpanded = eventId =>
    setEventExpandedMap(prevEventExpandedMap => ({
      ...prevEventExpandedMap,
      [eventId]: !prevEventExpandedMap[eventId],
    }));

  let tabBody;

  switch (tab) {
    case tabs.EVENTS:
      tabBody = (
        <Events
          callbackUrl={item.eventCallbackUrl}
          events={item.events}
          eventExpandedMap={eventExpandedMap}
          merchantId={item.merchantId}
          orderId={item.orderId}
          toggleExpanded={toggleExpanded}
          refreshOrder={loadOrder}
          totalQuantity={item.quantity}
          itemId={item.itemId}
        />
      );
      break;

    case tabs.OVERVIEW:
      tabBody = <StatusOverview item={item} itemHistoryData={itemHistoryData} allStatuses={item.statuses} />;
      break;

    case tabs.SHIPMENTS:
      tabBody = <Shipments {...shipmentData} />;
      break;

    case tabs.CHANGE_REQUESTS:
      tabBody = <ChangeRequests changeRequestsData={changeRequestsData} item={item} />;
      break;

    case tabs.CANCELLATION_REQUESTS:
      tabBody = <CancellationRequests events={item.events} />;
      break;

    default:
      tabBody = <Details item={item} permissions={permissions} />;
      break;
  }

  return (
    <div ref={ref}>
      <Accordion
        className="accordion-ghost"
        variant={'ghost'}
        onHeaderClick={onHeaderClick}
        bodyStyle={accordionBodyStyle}
        headerStyle={{ display: 'flex', alignItems: 'center' }}
        title={
          <ItemHeader onMoreDetailsClick={onMoreDetailsClick} item={item} shipmentData={shipmentData.shipmentData} />
        }
        customOpen={isOpen}>
        <div className="clearfix">
          <ul className="nav nav-pills clickable pull-left">
            <li className={tab === tabs.DETAILS ? 'active' : ''}>
              <a onClick={onTabClicked} name={tabs.DETAILS}>
                {intl.formatMessage({ id: 'DetailPanels.Details' })}
              </a>
            </li>
            <li className={tab === tabs.OVERVIEW ? 'active' : ''}>
              <a onClick={onTabClicked} name={tabs.OVERVIEW}>
                {intl.formatMessage({ id: 'DetailPanels.StatusOverview' })}
              </a>
            </li>
            <li className={tab === tabs.EVENTS ? 'active' : ''}>
              <a onClick={onTabClicked} name={tabs.EVENTS}>
                {intl.formatMessage({ id: 'DetailPanels.Events' })}
              </a>
            </li>
            {item.events && item.events.find(event => event.eventType === 'cancellationRequestReceived') && (
              <li className={tab === tabs.CANCELLATION_REQUESTS ? 'active' : ''}>
                <a onClick={onTabClicked} name={tabs.CANCELLATION_REQUESTS}>
                  {intl.formatMessage({ id: 'DetailPanels.CancellationRequests' })}
                </a>
              </li>
            )}
            {!isEmpty(item._links.changeRequests) && (
              <li className={tab === tabs.CHANGE_REQUESTS ? 'active' : ''}>
                <a onClick={onTabClicked} name={tabs.CHANGE_REQUESTS}>
                  {intl.formatMessage({ id: 'DetailPanels.ChangeRequests' })}
                </a>
              </li>
            )}
            {!isEmpty(item.shipments) && (
              <li className={tab === tabs.SHIPMENTS ? 'active' : ''}>
                <a onClick={onTabClicked} name={tabs.SHIPMENTS}>
                  {intl.formatMessage({ id: 'DetailPanels.Shipments' })}
                </a>
              </li>
            )}
          </ul>
          <ItemActions item={item} orderId={item.orderId} />
        </div>
        <div>{tabBody}</div>
      </Accordion>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  onContainerClick: PropTypes.func,
  permissions: PropTypes.object,
  loadOrder: PropTypes.func,
  loadBuyerProductPricingForItem: PropTypes.func,
  loadBuyerShippingPricingForItem: PropTypes.func,
  loadFulfiller: PropTypes.func,
  loadFulfillerDetails: PropTypes.func,
  loadProductManufacturing: PropTypes.func,
  loadPrintJob: PropTypes.func,
  isLoadingFulfillers: PropTypes.bool,
  intl: PropTypes.object,
};

Item.defaultProps = {
  isOpen: false,
  onContainerClick: () => undefined,
};

const mapStateToProps = ({ fulfillers: { loading } }) => ({ isLoadingFulfillers: loading });

export default connect(mapStateToProps, {
  loadOrder,
  loadBuyerProductPricingForItem,
  loadBuyerShippingPricingForItem,
  loadFulfiller,
  loadFulfillerDetails,
  loadProductManufacturing,
  loadPrintJob,
})(injectIntl(Item));
