/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NoOptionsNetworkError } from './NoOptionsNetworkError';
import { UseQueryResult } from 'react-query';
import { InvalidConsideredOption, Option, Problem } from '../../types';
import { wrapper } from '../../sharedStyles';
import { GenericNoOptions } from './GenericNoOptions';

const noFulfillmentOptionWrapper = css`
  padding: 1.6rem 0;

  > * {
    padding: 0 1.6rem; // apply left/right padding to the direct children so the scrollbar doesn't overlap them on short height screens
  }
`;

type NoOptionsProps = {
  routingProblem: Problem | undefined;
  options: UseQueryResult<Option | InvalidConsideredOption, Error>[];
  orderedSkuCode: string | undefined;
  routingRequestUrl: string | undefined;
};

export const NoOptions = ({ routingProblem, options, orderedSkuCode, routingRequestUrl }: NoOptionsProps) => {
  if (routingProblem && options.length === 0) {
    return (
      <div css={[wrapper, noFulfillmentOptionWrapper]} data-testid="network-error-no-fulfillment-options">
        <h3>Fulfillment Options</h3>
        <NoOptionsNetworkError routingProblem={routingProblem} routingRequestUrl={routingRequestUrl} />
      </div>
    );
  }

  return (
    <div css={[wrapper, noFulfillmentOptionWrapper]} data-testid="generic-no-fulfillment-options">
      <h3>Fulfillment Options</h3>
      <GenericNoOptions orderedSkuCode={orderedSkuCode} />
    </div>
  );
};
