/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RetryAlert } from './Retry/RetryAlert';
import { RoutingRequestOverview } from './RoutingRequestOverview';
import { FulfillmentOptions } from './FulfillmentOptions';
import { isItemSuspendedInRouting } from '../helpers/item';
import { FailedDecision, Item, SuccessfulDecision } from '../types';

const decisionViewerStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1rem;
`;

export type DecisionTabProps = {
  item?: Item | undefined;
  initialDecision?: SuccessfulDecision | FailedDecision | undefined;
  routingDecision?: SuccessfulDecision | FailedDecision | undefined;
  decisionLink?: string | undefined;
  routingRequestUrl: string | undefined;
  showDecisionLinks: boolean | undefined;
  halfWeight: boolean | undefined;
  initialDecisionUrl?: string;
  diff: Set<string> | undefined;
};

export const DecisionTab = ({
  item,
  routingDecision,
  decisionLink,
  routingRequestUrl,
  showDecisionLinks,
  halfWeight,
  diff,
}: DecisionTabProps) => {
  return (
    <div css={decisionViewerStyles}>
      <RetryAlert />
      <RoutingRequestOverview
        item={item}
        routingDecision={routingDecision}
        decisionLink={decisionLink}
        showDecisionLinks={showDecisionLinks}
        halfWeight={halfWeight}
        diff={diff}
      />
      <FulfillmentOptions
        routingDecision={routingDecision}
        routingRequestUrl={routingRequestUrl}
        orderedSkuCode={item?.orderedSkuCode}
        isItemSuspendedInRouting={isItemSuspendedInRouting(item)}
        halfWeight={halfWeight}
      />
    </div>
  );
};
