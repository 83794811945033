import React, { useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { Card } from '@cimpress/react-components';
import { Alert } from '@cimpress/react-components/lib';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import DateDisplay from '../shared/dateDisplay';
import FieldDisplay from './FieldDisplay';
import JSONModal from './actions/JSONModal';
import { getChangeRequestsByItemId } from '../../services/changeRequestRouter';
import PreviewCarousel from '@cimpress-technology/mex-preview-carousel';
import AddressPopover from './AddressPopover';
import auth from '../../utils/auth';
import { camelCase, isEmpty, startCase, upperFirst } from 'lodash';
import { callFetch } from '../../services/serviceHelpers';
import Label from '@cimpress/react-components/lib/Label';

const columnStyle = { minWidth: '300px', maxWidth: '400px', flex: '1' };

const changeRequestsState = {
  isLoading: false,
  changeRequestContexts: [],
  errorMessage: null,
};

const changeTypes = {
  DELIVERY: 'delivery',
  SHIPMENT_REQUIREMENT: 'shipmentRequirement',
  ARTWORK: 'artwork',
};

const populateAddressesFromDeliveryConfig = async (
  deliveryConfigurations = [],
  changeRequestContext,
  isPreviousData
) => {
  // Fetching and pushing all changeRequest addresses to changeRequestContext
  let targetField = 'requestedData';

  if (isPreviousData) {
    targetField = 'previousData';
  }

  await Promise.all(
    deliveryConfigurations.map(async deliveryConfiguration => {
      const deliveryRequest = await callFetch(deliveryConfiguration.deliveryRequestLink.href, 'GET');

      if (deliveryRequest._links?.pickupPoint && deliveryRequest.destinationAddress) {
        if (
          !deliveryRequest.destinationAddress.company &&
          !deliveryRequest.destinationAddress.firstName &&
          !deliveryRequest.destinationAddress.lastName
        ) {
          const pickupPoint = await callFetch(deliveryRequest._links.pickupPoint.href, 'GET');
          changeRequestContext[targetField].pup.push({
            ...deliveryRequest.destinationAddress,
            company: pickupPoint.name,
          });
        } else {
          changeRequestContext[targetField].pup.push(deliveryRequest.destinationAddress);
        }
      } else if (deliveryRequest.destinationAddress) {
        //In case the address is a standard destinationAddress since no pickup point link is present
        changeRequestContext[targetField].destinationAddress.push(deliveryRequest.destinationAddress);
      } else {
        throw new Error(
          'No address present in Delivery Request. Please refer to the deliveryRequestLink: ' +
            deliveryConfiguration.deliveryRequestLink.href
        );
      }
    })
  );
  return changeRequestContext;
};

const populateChangeRequestContexts = async item => {
  const itemId = item.itemId;

  const itemChangeRequests = await getChangeRequestsByItemId(itemId);

  //Checking for split-shipped items to populate the addresses
  try {
    const changeRequestContexts = await Promise.all(
      itemChangeRequests.map(async changeRequest => {
        let changeRequestContext = {
          changeRequest,
          previousData: {},
          requestedData: {},
          itemId,
        };

        if (changeRequest.changeFrom) {
          const itemChangeFrom = changeRequest.changeFrom.find(changeFrom => changeFrom.itemId === itemId);

          //Extract changeFrom object from shipmentRequirement and artwork change requests
          if (changeRequest.changeDetails.changeType !== changeTypes.DELIVERY) {
            changeRequestContext.previousData = itemChangeFrom;
            changeRequestContext.requestedData = changeRequest.changeDetails;
          } else {
            //Delivery Change Requests are processed differently from artwork and shipmentRequirement change requests
            //due to there possibly being more than one element being changed on the item (multiple addresses for split-ship or pickup-points)
            changeRequestContext.previousData = { destinationAddress: [], pup: [] };
            changeRequestContext.requestedData = { destinationAddress: [], pup: [] };

            if (changeRequest.changeDetails.itemDeliveryConfigurations) {
              // Populate new addresses requested in change request
              changeRequestContext = await populateAddressesFromDeliveryConfig(
                changeRequest.changeDetails.itemDeliveryConfigurations?.find(
                  itemDeliveryConfiguration => itemDeliveryConfiguration.itemId === itemId
                ).deliveryConfigurations,
                changeRequestContext,
                false
              );
            } else {
              if (changeRequest.changeDetails.destinationAddress) {
                changeRequestContext.requestedData.destinationAddress.push(
                  changeRequest.changeDetails.destinationAddress
                );
              }
            }

            // Populate existing addresses affected by change request
            if (itemChangeFrom) {
              changeRequestContext = await populateAddressesFromDeliveryConfig(
                itemChangeFrom.deliveryConfigurations,
                changeRequestContext,
                true
              );
            }
          }
        }
        return changeRequestContext;
      })
    );

    return changeRequestContexts;
  } catch (error) {
    return itemChangeRequests.map(changeRequest => {
      return { changeRequest };
    });
  }
};

export const useChangeRequestsData = (itemId, item) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'REQUEST':
        return { ...changeRequestsState, isLoading: true };
      case 'SUCCESS':
        return {
          ...changeRequestsState,
          itemChangeRequests: action.itemChangeRequests,
          changeRequestContexts: action.changeRequestContexts,
        };
      case 'FAILURE':
        return { ...changeRequestsState, errorMessage: action.errorMessage };
      default:
        return state;
    }
  });

  useEffect(() => {
    dispatch({ type: 'REQUEST' });
    const getItemChangeRequests = async () => {
      try {
        const changeRequestContexts = await populateChangeRequestContexts(item);
        dispatch({ type: 'SUCCESS', changeRequestContexts });
      } catch (error) {
        dispatch({ type: 'FAILURE', errorMessage: error.message });
      }
    };
    getItemChangeRequests();
  }, [itemId]);
  return state;
};

const ChangeRequests = ({ changeRequestsData, item }) => {
  const intl = useIntl();
  const { isLoading, changeRequestContexts, errorMessage } = changeRequestsData;

  let changeRequests = [];

  const itemChangeRequests = changeRequestContexts.map(changeRequestContext => {
    return changeRequestContext.changeRequest;
  });

  const displayInformation = (changeRequestContext, changeType, displayData, labelTag) => {
    switch (changeType) {
      case changeTypes.ARTWORK:
        return (
          <>
            <FieldDisplay
              newline
              name={intl.formatMessage({ id: 'Artwork.' + labelTag })}
              value={
                <PreviewCarousel
                  pxSize={100}
                  minimal
                  hideIndicators
                  auth={auth}
                  mcpSku={item.skuCode}
                  docRefUrl={displayData.documentReferenceUrl}
                  variableAttributes={changeRequestContext.changeRequest?._embedded?.items[0]?.variableAttributes || []}
                  fallbackPreview={intl.formatMessage({ id: 'ItemActions.NoPreview' })}
                  embiggenButton
                  includePreviewInfo
                  includeMerchandising={!item.documentReferenceUrl && !item.manufacturingReadyDataUrl}
                />
              }
            />
          </>
        );
      case changeTypes.SHIPMENT_REQUIREMENT:
        return (
          <>
            {displayData.localPromisedArrivalDate && (
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'ArrivalDate.' + labelTag })}
                value={<DateDisplay displayRawDate date={displayData.localPromisedArrivalDate} />}
              />
            )}
            {!isEmpty(displayData.deliveryConstraints) && (
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'DeliveryConstraints.' + labelTag })}
                value={
                  <Card isMinimal style={{ margin: '0px' }}>
                    {Object.entries(displayData.deliveryConstraints).map(([constraintKey, constraintData]) => {
                      return (
                        <FieldDisplay
                          newline
                          name={intl.formatMessage({
                            id: 'DeliveryConstraints.' + upperFirst(camelCase(constraintKey)),
                          })}
                          value={constraintData}
                        />
                      );
                    })}
                  </Card>
                }
              />
            )}
          </>
        );
      case changeTypes.DELIVERY:
        return (
          <>
            {/* Standard Address Field */}
            {!isEmpty(displayData.destinationAddress) && (
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'Address.' + labelTag })}
                value={
                  !isEmpty(displayData.destinationAddress) ? (
                    <AddressPopover addresses={displayData.destinationAddress} />
                  ) : (
                    <>No Destination Address Provided</>
                  )
                }
              />
            )}

            {/* Pickup Point Field */}
            {!isEmpty(displayData.pup) && (
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'PickupPoint.' + labelTag })}
                value={<AddressPopover addresses={displayData.pup} />}
              />
            )}
          </>
        );
      default:
        return (
          <Alert
            message={
              'Invalid Change Request Type. Please contact Cimpress platform support or contact OPTICSquad@cimpress.com'
            }
          />
        );
    }
  };

  if (!errorMessage && changeRequestContexts[0]?.itemId) {
    itemChangeRequests.forEach((changeRequest, index) => {
      const showHorizontalLine = index < itemChangeRequests.length - 1;

      const changeRequestContext = changeRequestContexts[index];

      let itemLevelChangeRequestStatus = changeRequest.requestStatus.state;

      const matchingItemLevelStatus = changeRequest.requestStatus.itemStatus?.find(
        itemStatus => itemStatus.name === item.itemId
      );

      if (matchingItemLevelStatus) {
        itemLevelChangeRequestStatus = matchingItemLevelStatus.state;
      }

      const previousData = changeRequestContext.previousData;
      const requestedData = changeRequestContext.requestedData;

      changeRequests.push(
        <>
          {showHorizontalLine && <hr />}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ ...columnStyle, paddingRight: '5px' }}>
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'ChangeRequest.RequestType' })}
                value={startCase(changeRequest.changeDetails.changeType)}
              />
              {previousData &&
                displayInformation(
                  changeRequestContext,
                  changeRequest.changeDetails.changeType,
                  previousData,
                  'Previous'
                )}
            </div>
            <div style={{ ...columnStyle, paddingRight: '5px' }}>
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'StatusOverview.CurrentItemState' })}
                value={startCase(item.computedStatus.type)}
              />
              {displayInformation(
                changeRequestContext,
                changeRequest.changeDetails.changeType,
                requestedData,
                'Requested'
              )}
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'ChangeRequest.RequestDate' })}
                value={<DateDisplay date={changeRequest.createdAt} />}
              />
              {changeRequest._links.preferredOrder?.name && (
                <FieldDisplay
                  newline
                  name={intl.formatMessage({ id: 'ChangeRequest.PreferredOrderId' })}
                  value={
                    <a
                      href={`${process.env.REACT_APP_OOPS_UI_URL}/orders/${changeRequest._links.preferredOrder.name}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {changeRequest._links.preferredOrder.name}
                    </a>
                  }
                />
              )}
              {changeRequest.createdOnBehalfOf && (
                <FieldDisplay
                  newline
                  name={intl.formatMessage({ id: 'ChangeRequest.RequestedOnBehalfOf' })}
                  value={changeRequest.createdOnBehalfOf}
                />
              )}
            </div>
            <div style={{ ...columnStyle, paddingRight: '5px' }}>
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'ChangeRequest.Status' })}
                value={
                  <Label
                    text={startCase(itemLevelChangeRequestStatus)}
                    status={
                      itemLevelChangeRequestStatus === 'accepted'
                        ? 'success'
                        : itemLevelChangeRequestStatus === 'rejected'
                        ? 'danger'
                        : itemLevelChangeRequestStatus === 'preparing'
                        ? 'warning'
                        : 'primary'
                    }
                  />
                }
              />
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'ChangeRequest.ResponseDate' })}
                value={
                  changeRequest.requestStatus.state === 'accepted' ||
                  changeRequest.requestStatus.state === 'rejected' ? (
                    <DateDisplay date={changeRequest.modifiedAt} />
                  ) : (
                    'Pending'
                  )
                }
              />
            </div>
          </div>
          <JSONModal
            dataType={intl.formatMessage({ id: 'ChangeRequest.ChangeRequest' })}
            json={changeRequest}
            variant={'default'}
          />
        </>
      );
    });

    changeRequests = changeRequests.reverse();
  } else if (!isEmpty(itemChangeRequests)) {
    itemChangeRequests.forEach((changeRequest, index) => {
      const showHorizontalLine = index <= itemChangeRequests.length - 1;

      changeRequests.push(
        <>
          {showHorizontalLine && <hr />}
          <Alert message={'Error loading Change Request Details. Change Request JSON is provided below'} />
          <JSONModal
            dataType={intl.formatMessage({ id: 'ChangeRequest.ChangeRequest' })}
            json={changeRequest}
            variant={'default'}
          />
        </>
      );
    });
  } else {
    changeRequests = [];
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : errorMessage && isEmpty(changeRequests) ? (
        <Alert message={errorMessage} />
      ) : (
        <>
          <h3>Change Request History</h3>
          {changeRequests}
        </>
      )}
    </>
  );
};

export default ChangeRequests;
