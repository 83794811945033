import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Tooltip, colors } from '@cimpress/react-components';
import { ItemRoutingDecisionViewer } from '@cimpress-technology/routing-decision-viewer-components';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { withRouter } from 'react-router';
import max from 'lodash/max';
import get from 'lodash/get';
import every from 'lodash/every';
import { ItemPriceSummary } from '@cimpress-technology/react-pricing-troubleshooter';
import { callFetch } from '../../services/serviceHelpers';
import auth from '../../utils/auth';
import { initPrintJobStatuses } from '../../constants/printJob';
import { SIViewer } from '@cimpress-technology/react-supplier-integrations';
import ActionLink from '../shared/ActionLink';
import Attributes from './Attributes';
import SkuDetails from './SkuDetails';
import OtherIds from './actions/OtherIds';
import AdditionalLinks from './actions/AdditionalLinks';
import DateDisplay from '../shared/dateDisplay';
import FieldDisplay from './FieldDisplay';
import AddressPopover from './AddressPopover';
import FulfillerPopover from './FulfillerPopover';
import FulfillerDeniedPopover from './FulfillerDeniedPopover';
import ShipmentPlanModal from './ShipmentPlanModal';
import { orderHasRoutingDecision } from '../../utils/routing';

const { danger } = colors;

const columnStyle = { minWidth: '300px', maxWidth: '400px', flex: '1' };

export const Details = ({ item, history, location, permissions, userLanguage, intl }) => {
  const [showSuspendedOrdersItemModal, setShowSuspendedOrdersItemModal] = useState(false);
  const fulfillmentGroupId = get(item, 'fulfillmentGroupId');
  const itemServiceUrl = get(item, '_links.self.href');

  const onOpenSuspendedOrdersItemModal = () => {
    setShowSuspendedOrdersItemModal(true);
  };

  const onCloseSuspendedOrdersItemModal = () => {
    setShowSuspendedOrdersItemModal(false);
  };

  const {
    variableAttributes,
    productManufacturingData,
    destinationAddress,
    consigneeAddress,
    deliveryConfigurations,
    localPromisedArrivalDate,
    fulfiller,
    fulfillerDetails,
    fulfillerPermission,
    statuses,
    _links,
  } = item;
  const [productVersion, setProductVersion] = useState('');
  const [pickupPoints, setPickupPoints] = useState('');
  const [hasRoutingDecision, setHasRoutingDecision] = useState(false);

  // When determining the expected ship date, we need to verify whether the item has been marked as
  // delayed by the fulfiller.  If delayed, we will infer the new expected ship date based on the new (potentially)
  // expectedCloseDate for fulfillment.  This is our best guess with the data on hand.  Eventually the
  // item will have an updated shipment plan and we can eliminate this extra logic.
  const hasFulfillerMarkedAsDelayed = get(statuses, 'fulfillmentDelayed.state') === 'current';
  const dateFromShipmentPlan = max(
    deliveryConfigurations.map(config => config.shipmentPlan && config.shipmentPlan.expectedShipDate)
  );
  const expectedShipDate = hasFulfillerMarkedAsDelayed
    ? get(statuses, 'fulfillment.expectedCloseDate', dateFromShipmentPlan)
    : dateFromShipmentPlan;
  const shipmentPlanAnalysisLink = get(item, '_links.shipmentPlanningAnalysis.href');
  const hasOnTimeDeliveryOption = every(
    deliveryConfigurations,
    config =>
      config.shipmentPlan &&
      config.shipmentPlan.expectedDeliveryOption &&
      !config.shipmentPlan.expectedDeliveryOption.lateArrival
  );

  const attributes = { variableAttributes: variableAttributes, productManufacturingData: productManufacturingData };

  const printJobStatuses = initPrintJobStatuses(intl);
  const printJobStatus = printJobStatuses[get(item, 'printJob.status')];

  const productConfigurationLink = get(_links, 'productConfiguration.href');

  const hasSupplierIntegrationStepClosed = get(statuses, 'SupplierIntegrations.state') === 'closed';
  const accessToken = auth.getAccessToken();

  useEffect(() => {
    const fetchProductInformation = async () => {
      try {
        if (productConfigurationLink) {
          const response = await callFetch(productConfigurationLink, 'GET');
          setProductVersion(response.productVersion);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductInformation();
  }, [productConfigurationLink]);

  useEffect(() => {
    if (!destinationAddress) {
      const fetchDeliveryConfigurations = async () => {
        try {
          if (deliveryConfigurations[0]?.deliveryRequestLink?.href) {
            const deliveryRequest = await callFetch(deliveryConfigurations[0].deliveryRequestLink.href, 'GET');
            if (deliveryRequest._links.pickupPoint?.href) {
              const pickupPoint = await callFetch(deliveryRequest._links.pickupPoint.href, 'GET');
              if (pickupPoint.address.addressType === 'PickupPoint') {
                setPickupPoints({
                  country: pickupPoint.address.countryCode,
                  postalCode: pickupPoint.address.postalCode,
                  stateOrProvince: pickupPoint.address.state || pickupPoint.address.county,
                  city: pickupPoint.address.city,
                  company: '',
                  firstName: pickupPoint.name,
                  middleName: '',
                  lastName: '',
                  phone: pickupPoint.phoneNumber,
                  phoneExt: null,
                  email: null,
                  street1: pickupPoint.address.addressLine1,
                  street2: pickupPoint.address.addressLine2,
                  doorCode: null,
                  isPOBox: false,
                  isResidential: false,
                });
              }
            }
          }
        } catch (error) {
          console.error(`Error in fetchDeliveryConfiguration: ${error}`);
        }
      };
      fetchDeliveryConfigurations();
    }
  }, deliveryConfigurations);

  useEffect(() => {
    const checkRoutingDecision = async () => {
      setHasRoutingDecision(await orderHasRoutingDecision(item, accessToken));
    };

    checkRoutingDecision();
  }, [item.itemId, accessToken]);

  // this message on the print job is only set if we fail the call
  // to get a print job. we set it to the message from the exception
  // caught in the action to load the print job. if there is a message
  // we will display it in a tooltip below
  const printJobStatusMessage = get(item, 'printJob.message');

  let fulfillerData = fulfiller ? (
    <FulfillerPopover fulfiller={fulfiller} fulfillerDetails={fulfillerDetails} />
  ) : fulfillerPermission === false ? (
    <FulfillerDeniedPopover fulfillerId={item.globalFulfillerId} />
  ) : null;

  const deliveryCalculatorLink = `${process.env.REACT_APP_DELIVERY_CALCULATOR_URL}/?itemId=${item.itemId}`;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ ...columnStyle, paddingRight: '5px' }}>
        <h5>
          <FormattedMessage id="ItemLevelFields.ItemDetails" />
        </h5>
        <FieldDisplay
          name={intl.formatMessage({ id: 'ItemLevelFields.OrderedSku' })}
          value={<SkuDetails sku={item.orderedSkuCode} />}
        />
        {productVersion && (
          <FieldDisplay name={intl.formatMessage({ id: 'ItemLevelFields.ProductVersion' })} value={productVersion} />
        )}
        {item.orderedSkuCode !== item.skuCode ? (
          <FieldDisplay
            name={intl.formatMessage({ id: 'ItemLevelFields.FulfilledSku' })}
            value={<SkuDetails sku={item.skuCode} />}
          />
        ) : null}
        <FieldDisplay
          name={intl.formatMessage({ id: 'ItemLevelFields.AdditionalIDs' })}
          value={
            <OtherIds location={location} history={history} item={item}>
              {intl.formatMessage({ id: 'ItemLevelFields.ViewRelatedIDs' })}
            </OtherIds>
          }
        />
        <FieldDisplay
          name={intl.formatMessage({ id: 'ItemLevelFields.AdditionalLinks' })}
          value={
            <AdditionalLinks item={item} location={location} history={history}>
              <FormattedMessage id="ItemLevelFields.ViewLinks" />
            </AdditionalLinks>
          }
        />
        <FieldDisplay
          name={intl.formatMessage({ id: 'ItemLevelFields.Attributes' })}
          value={<Attributes attributes={attributes} />}
        />
      </div>
      <div style={{ ...columnStyle, padding: '0 5px' }}>
        <h5>
          <FormattedMessage id="ItemLevelFields.Pricing" />
        </h5>
        <ItemPriceSummary
          accessToken={accessToken}
          merchantId={item.merchantId}
          itemId={item.itemId}
          locale={userLanguage}
          createdDate={item.createdDate}
          buyerAccountId={item._links?.buyerAccount?.name}
          sellerAccountId={item._links?.sellerAccount?.name}
        />
      </div>
      <div style={{ ...columnStyle, paddingLeft: '5px' }}>
        <h5>
          <FormattedMessage id="ItemLevelFields.Fulfillment" />
        </h5>
        {/* TODO fix split shipped orders */}
        <FieldDisplay
          name={intl.formatMessage(
            destinationAddress
              ? { id: 'FulfillmentGroup.ShippedTo' }
              : pickupPoints
              ? { id: 'FulfillmentGroup.PickupFrom' }
              : { id: 'FulfillmentGroup.DeliveryInfo' }
          )}
          value={
            destinationAddress || pickupPoints ? (
              <AddressPopover
                addresses={[destinationAddress || pickupPoints]}
                hasChanged={item.hasDeliveryChange}
                hasFulfillerCorrected={item.hasFulfillerCorrectedAddress}
                shipmentDestinationAddresses={item.shipmentDestinationAddresses}
              />
            ) : (
              <FormattedMessage id="FulfillmentGroup.NotAvailable" />
            )
          }
        />
        <FieldDisplay
          name={intl.formatMessage({ id: 'FulfillmentGroup.BilledTo' })}
          value={
            consigneeAddress ? (
              <AddressPopover addresses={[consigneeAddress]} />
            ) : (
              <FormattedMessage id="FulfillmentGroup.NotAvailable" />
            )
          }
        />
        {hasRoutingDecision && (
          <FieldDisplay
            name={intl.formatMessage({ id: 'FulfillmentGroup.Fulfiller' })}
            value={
              <div>
                {fulfillerData}
                <ActionLink
                  text={intl.formatMessage({ id: 'FulfillmentGroup.RoutingDetails' })}
                  action={onOpenSuspendedOrdersItemModal}
                  style={{ padding: '0', width: 'auto' }}
                />
                <ItemRoutingDecisionViewer
                  accessToken={accessToken}
                  environment={process.env.REACT_APP_ENV === 'loc' ? 'int' : process.env.REACT_APP_ENV}
                  item={item}
                  isModal
                  showModal={showSuspendedOrdersItemModal}
                  closeModal={onCloseSuspendedOrdersItemModal}
                />
              </div>
            }
          />
        )}
        {expectedShipDate ? (
          <FieldDisplay
            name={intl.formatMessage({ id: 'FulfillmentGroup.ExpectedShipDate' })}
            value={
              <span>
                <span style={{ marginRight: '15px' }}>{<DateDisplay date={expectedShipDate} />}</span>
                {hasFulfillerMarkedAsDelayed ? (
                  <Label text={intl.formatMessage({ id: 'FulfillmentGroup.ExpectedShipDateUpdated' })} type="info" />
                ) : null}
                <ShipmentPlanModal isDelayed={hasFulfillerMarkedAsDelayed} item={item} accessToken={accessToken} />
              </span>
            }
          />
        ) : null}
        {hasSupplierIntegrationStepClosed && (
          <FieldDisplay
            name={intl.formatMessage({ id: 'FulfillmentGroup.ItemTransmissionDetail' })}
            value={
              <div>
                <SIViewer
                  accessToken={auth.getAccessToken()}
                  itemDetails={{ fulfillmentGroupId, itemServiceUrl }}
                  env={process.env.REACT_APP_ENV === 'prd' ? 'prd' : 'int'}
                />
              </div>
            }
          />
        )}
        <FieldDisplay
          name={intl.formatMessage({ id: 'FulfillmentGroup.PromiseDate' })}
          value={
            <span>
              {localPromisedArrivalDate ? (
                <div>
                  <DateDisplay date={localPromisedArrivalDate} displayRawDate />
                  <ActionLink
                    text={intl.formatMessage({ id: 'ShippingMessage.GoToDeliveryDateDiagnostics' })}
                    action={() => window.open(deliveryCalculatorLink, '_blank', 'noopener,noreferrer')}
                    style={{ padding: '0' }}
                  />
                </div>
              ) : null}
              {!expectedShipDate && shipmentPlanAnalysisLink && (
                <div style={{ display: 'flex' }}>
                  <ShipmentPlanModal isDelayed={hasFulfillerMarkedAsDelayed} item={item} accessToken={accessToken} />
                  {!hasOnTimeDeliveryOption && (
                    <IconAlertTriangle color={danger.base} weight="fill" style={{ marginLeft: '3px' }} />
                  )}
                </div>
              )}
            </span>
          }
        />
        {printJobStatus ? (
          <FieldDisplay
            name={intl.formatMessage({ id: 'ItemLevelFields.PrintJobIdAndStatus' })}
            value={
              printJobStatusMessage ? (
                <>
                  <span>{printJobStatus.displayName}</span>
                  <span>
                    <Tooltip contents={printJobStatusMessage}>
                      <IconInformationCircle style={{ marginLeft: '5px' }} className="text-info" weight="fill" />
                    </Tooltip>
                  </span>
                </>
              ) : (
                `${item.printJob.shortId}, ${printJobStatus.displayName}`
              )
            }
          />
        ) : null}
      </div>
    </div>
  );
};

Details.propTypes = {
  item: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  userLanguage: PropTypes.string,
  intl: PropTypes.object,
};

const mapStateToProps = ({ userPreferences: { userLanguage } }) => ({ userLanguage });

export default withRouter(connect(mapStateToProps)(injectIntl(Details)));
