import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { getErrorMessage } from '../helpers/errorStatus';
import { determineProductAttributes } from '../helpers/productAttributes';
import { Item } from '../types';
import { fetchWithAuth } from './fetchWithAuth';
import { getMcpProductByMcpSku } from './productIntroduction';

export type GetItemByIdParams = {
  accessToken: string;
  decisionLink?: string;
  itemLink: string | undefined;
};

export const getItemByItemId = async ({ accessToken, decisionLink, itemLink }: GetItemByIdParams): Promise<Item> => {
  if (!itemLink) {
    const errorJson: RoutingUIError = {
      name: 'ROUTING_DECISION_ERROR',
      message: 'There is no item ID on the routing decision.',
    };

    if (decisionLink) {
      errorJson.link = {
        href: decisionLink,
        name: 'Routing decision link',
      };
    }

    throw new RoutingUIError(errorJson);
  }

  const response = await fetchWithAuth({ path: itemLink, accessToken });

  if (!response.ok) {
    throw new RoutingUIError({
      name: 'ERROR',
      message: getErrorMessage({
        erroredItem: `item ${itemLink} from the Item Service`,
        statusCode: response.status,
      }),
      link: { href: itemLink, name: 'Item service link' },
    });
  }

  const item = (await response.json()) as Item;

  try {
    const productAttributeResponse = await getMcpProductByMcpSku({ accessToken, mcpSku: item.orderedSkuCode });
    const productAttributes = determineProductAttributes(productAttributeResponse);

    if (productAttributes) {
      item.productAttributes = productAttributes;
    }
  } catch (error) {
    // Swallow the error since while it's nice to have this information, we can still show the rest of
    // the decision information without it
  }

  return item;
};
