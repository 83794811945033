/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Dropdown, Button } from '@cimpress/react-components';
import IconNavigationMenuVertical from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical';

type OverviewDropdownProps = {
  showDecisionLinks?: boolean;
  decisionLink?: string;
};

const dropDownStyles = css`
  // Keeps hamburger button from slightly overlapping parent div border
  margin-right: 1px;

  ul {
    left: auto;
    right: 0;
  }

  // Hide the initial decision list item (MOTR only)
  // when it's empty while waiting to get the decision link
  li:empty {
    display: none;
  }

  a {
    width: max-content;
    text-align: left;
  }
`;

export const OverviewDropdown = ({ showDecisionLinks = true, decisionLink }: OverviewDropdownProps) => {
  return (
    <Dropdown
      title={<IconNavigationMenuVertical weight="fill" />}
      variant="simple"
      css={dropDownStyles}
      data-testid="overview-dropdown"
    >
      {showDecisionLinks && decisionLink && (
        <Button href={decisionLink} variant="anchor" target="_blank" data-testid="current-decision-link">
          View decision JSON
        </Button>
      )}
      <Button
        href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/14935785539"
        variant="anchor"
        target="_blank"
        data-testid="documentation-link"
      >
        View routing documentation
      </Button>
    </Dropdown>
  );
};
