/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { get } from 'lodash';
import { FulfillmentOption } from './FulfillmentOption/FulfillmentOption';
import {
  FailedDecision,
  InvalidConsideredOption,
  isValidOption,
  isFailedDecision,
  Option,
  SuccessfulDecision,
} from '../types';
import { wrapper } from '../sharedStyles';
import { useDecorateOptions } from '../hooks/useDecorateOptions';
import { AccessContext } from './DecisionViewer';
import { NoOptions } from './NoData/NoOptions';

const fulfillmentOptionWrapper = css`
  padding: 1.6rem 0 0;

  > h3 {
    padding: 0 1.6rem;
  }

  .scrollBox {
    box-sizing: border-box;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
`;

const scrollBoxWrapperStyles = css`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  overflow-y: auto;
`;

export const FulfillmentOptions = ({
  routingDecision,
  orderedSkuCode,
  routingRequestUrl,
  isItemSuspendedInRouting,
  halfWeight,
}: {
  routingDecision: SuccessfulDecision | FailedDecision | undefined;
  orderedSkuCode: string | undefined;
  routingRequestUrl: string | undefined;
  isItemSuspendedInRouting: boolean;
  halfWeight?: boolean;
}) => {
  const { accessToken, environment } = useContext(AccessContext);

  const decoratedOptions = useDecorateOptions({
    environment,
    accessToken,
    decision: routingDecision,
    options: routingDecision?.consideredOptions,
  });

  if (!decoratedOptions || !decoratedOptions.length || decoratedOptions.length === 0) {
    const routingProblem =
      routingDecision && isFailedDecision(routingDecision) ? get(routingDecision, ['problem']) : undefined;

    return (
      <div data-testid="decision-viewer-body-with-errors">
        <NoOptions
          routingProblem={routingProblem}
          options={decoratedOptions}
          orderedSkuCode={orderedSkuCode}
          routingRequestUrl={routingRequestUrl}
        />
      </div>
    );
  }

  if (decoratedOptions.some((option) => !option.isFetched)) {
    return null;
  }

  const optionData = decoratedOptions.reduce(
    (acc, option) => {
      if (!option.error && option.data) {
        acc.push(option.data);
      }

      return acc;
    },
    [] as (Option | InvalidConsideredOption)[],
  );

  const sortedOptions = optionData.sort((a, b) => {
    if (isValidOption(a) && a.rank && isValidOption(b) && b.rank) {
      return Number(a.rank) - Number(b.rank);
    } else if (isValidOption(a) && a.rank) {
      return -1;
    } else if (isValidOption(b) && b.rank) {
      return 1;
    } else {
      // Sort by alphanumeric SKU if we don't have a rank to sort by (i.e., invalid options)
      return a.referenceId.localeCompare(b.referenceId, 'en', {
        numeric: true,
        sensitivity: 'base',
      });
    }
  });

  return (
    <div css={[wrapper, fulfillmentOptionWrapper]} data-testid="fulfillment-options">
      <h3 className={halfWeight ? 'halfWeight' : ''}>Fulfillment Options</h3>
      <div css={scrollBoxWrapperStyles}>
        <ul className="scrollBox">
          {sortedOptions.map((option) => (
            <li key={`${option.optionId}-list-item`} data-testid="option-list-item">
              <FulfillmentOption
                option={option}
                isItemSuspendedInRouting={isItemSuspendedInRouting}
                halfWeight={halfWeight}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
