import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { getErrorMessage } from '../helpers/errorStatus';
import { Environment, ItemRoutingRequestResponse, LinkWithName, RoutingRequestResponse } from '../types';
import { fetchWithAuth } from './fetchWithAuth';

const INT_BASE_URL = 'https://int-request-manager.lynx.cimpress.io';
const PROD_BASE_URL = 'https://request-manager.lynx.cimpress.io';

const getBaseUrl = (environment: Environment): string => (environment === 'int' ? INT_BASE_URL : PROD_BASE_URL);

export type OptionSelection = {
  itemLink: LinkWithName;
  chosenProductUrl: string;
  chosenFCUrl: string;
};

export type RetryRoutingRequestParams = {
  environment: Environment;
  accessToken: string;
  routingRequestId: string;
  selections?: OptionSelection[];
};

export async function retryRoutingRequest({
  environment,
  accessToken,
  routingRequestId,
  selections,
}: RetryRoutingRequestParams): Promise<void> {
  const path = `${getBaseUrl(environment)}/v1/routingRequests/${routingRequestId}:retry`;

  const body =
    selections && selections.length
      ? JSON.stringify({
          _embedded: {
            itemRoutingRequest: selections.map((r) => ({
              _links: {
                item: r.itemLink,
                productConfiguration: { href: r.chosenProductUrl },
                fulfillmentConfiguration: { href: r.chosenFCUrl },
              },
            })),
          },
        })
      : undefined;
  const response = await fetchWithAuth({ path, accessToken, body, method: 'POST', contentType: 'application/json' });

  if (!response.ok) {
    if (response.status === 422) {
      const error = (await response.json()) as Error;

      // Sometimes, we get a 422 error because a routing request retry attempt is still in progress
      // The request statusChanges array might show this, but there can be a race condition between making
      // the request and getting the updated routing request.
      throw new Error(`${error.message} This may mean that the routing request is already being retried`);
    }
    throw new Error(
      `Error when attempting to retry the routing request ${routingRequestId}. Received status code ${response.status}.`,
    );
  }
}

export type GetRoutingRequestParams = {
  accessToken: string;
  routingRequestUrl: string | undefined;
  enabled?: boolean;
  onSuccess?: (data: { routingRequest: RoutingRequestResponse | undefined }) => void;
};

export type GetItemRoutingRequestParams = {
  accessToken: string;
  routingRequestUrl: string | undefined;
  enabled?: boolean;
  onSuccess?: (data: { routingRequest: ItemRoutingRequestResponse | undefined }) => void;
  onError?: (error: RoutingUIError | Error) => void;
};

export async function getRoutingRequest({ accessToken, routingRequestUrl }: GetRoutingRequestParams) {
  if (!routingRequestUrl) {
    throw new RoutingUIError({
      name: 'ROUTING_REQUEST_ERROR',
      message: 'We were unable to find a routing request URL on the item.',
    });
  }

  const response = await fetchWithAuth({ path: routingRequestUrl, accessToken });
  if (!response.ok) {
    throw new RoutingUIError({
      name: 'ROUTING_REQUEST_ERROR',
      message: getErrorMessage({ erroredItem: 'routing request', statusCode: response.status }),
      ...(routingRequestUrl && { link: { href: routingRequestUrl, name: 'Routing request URL' } }),
    });
  }

  return { routingRequest: (await response.json()) as RoutingRequestResponse };
}

export async function getItemRoutingRequest({ accessToken, routingRequestUrl }: GetItemRoutingRequestParams) {
  if (!routingRequestUrl) {
    throw new RoutingUIError({
      name: 'ROUTING_REQUEST_ERROR',
      message: 'We were unable to find a routing request URL on the item.',
    });
  }

  const response = await fetchWithAuth({ path: routingRequestUrl, accessToken });
  if (!response.ok) {
    throw new RoutingUIError({
      name: 'ROUTING_REQUEST_ERROR',
      message: getErrorMessage({ erroredItem: 'routing request', statusCode: response.status }),
      ...(routingRequestUrl && { link: { href: routingRequestUrl, name: 'Item routing request URL' } }),
    });
  }

  return { routingRequest: (await response.json()) as ItemRoutingRequestResponse };
}
